import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useListHandle = () => {
  const navigate = useNavigate()
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleGoToEdit = (doc_id) => {
    navigate(`/edit/${doc_id}`)
  }

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  return {
    selectedRowKeys,
    setSelectedRowKeys,
    handleGoToEdit,
    onSelectChange,
  }

}

export default useListHandle;