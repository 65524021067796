import { useState} from 'react'

const useSearch = () => {
  const [searchText, setSearchText] = useState('')

  const onKeyDown = (event, handleSearch) => {
    if (event.keyCode == 13) {
      handleSearch()
    }
  }

  return {
    searchText,
    setSearchText,
    onKeyDown
  }
}

export default useSearch;