import { v4 as uuidv4 } from 'uuid';

export const uuid = () => {
  return uuidv4();
}

export const getUserInfo = () => {
  const user = localStorage.getItem("userInfo")
  if(user) {
    return JSON.parse(user).user
  }
  return null;
}


export const randomColor = () => {
  let random = '#'
  for(let i=0; i<6; i++){
      random += parseInt(Math.random() * 15).toString(16)  //随机数取整，并转换成16进制
  }
  return random    //返回随机数
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

const formatNow = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hour = now.getHours();
  const minute = now.getMinutes();
  const second = now.getSeconds();
  return [[year, month, day].map(formatNumber).join('-'),[hour, minute, second].map(formatNumber).join(':'), now.getTime(), year];
}

export const formatTime = time => {
  let now = formatNow();
  let tArr = time.split(' ')
  let timeStamp = new Date(time).getTime()
  let tYear = tArr[0]
  let tTime = tArr[1]
  let tMonth = `${tYear.split('-')[1]}-${tYear.split('-')[2]}`
  let tDay = `${tMonth} ${tTime.split(':')[0]}:${tTime.split(':')[1]}`
  let tDayTime = `${tTime.split(':')[0]}:${tTime.split(':')[1]}`

  return now[0] == tYear ? `今天 ${tDayTime}` : (
    now[2] - timeStamp < 86400000 ? `昨天 ${tDayTime}` : (
      now[2] - timeStamp < 172800000 ? `前天 ${tDayTime}` : (
        now[3] == tYear.split('-')[0] ? `${tDay}` : `${tYear}`
      )
    )
  )
}