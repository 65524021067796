import { useState } from "react";
import { message } from 'antd';
import copy from 'copy-to-clipboard';
const useShowModal = () => {
  const [docInfo, setDocInfo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (doc) => {
    setDocInfo(doc)
    setIsModalVisible(true);
  };

  const copyUrl = () => {
    copy(`${window.location.origin}/#/login?redirect=${docInfo.doc_id}`);
    message.success('复制成功, 快去分享给朋友吧~');
  }

  const changeCanvasToPic = () => {
    const canvasImg = document.getElementById('qrcode'); // 获取canvas类型的二维码
    const img = new Image();
    img.src = canvasImg.toDataURL('image/png'); // 将canvas对象转换为图片的data url
    const downLink = document.getElementById('down_link');
    downLink.href = img.src;
    downLink.download = '分享二维码'; // 图片name
    downLink.click()
    downLink.href = ''
    downLink.download = ''
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return {
    docInfo,
    isModalVisible,
    copyUrl,
    showModal,
    handleOk,
    handleCancel,
    setIsModalVisible,
    changeCanvasToPic
  }
}

export default useShowModal;