import React, { useRef, useEffect } from "react";
import { HashRouter, Link, Route, Routes, useLocation, Navigate } from "react-router-dom"
import QuillEditor from "./views/QuillEditor";
import Home from "./views/Home";
import {Login} from './components/Login'
import Self from "./views/Self";
import Trash from './views/Trash'
import NotFound from './views/error/NotFound'
import LayoutComponent from "./Layout/LayoutComponent";
import './App.css'

import Search from "./components/Search";

function useAuth() {
  let userInfo = localStorage.getItem("userInfo")
  try {
    userInfo = JSON.parse(userInfo)
    return (typeof userInfo == "object" && userInfo.token && userInfo.user) ? userInfo : null;
  } catch (err) {
    localStorage.clear()
    return null;
  }
}


function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation()
  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  return children;
}

const App = () => {

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<RequireAuth><LayoutComponent /></RequireAuth>}>
            <Route path="/" exact element={<RequireAuth><Home /></RequireAuth>} />
            <Route path="/mydoc" exact element={<RequireAuth><Self /></RequireAuth>} />
            <Route path="/search" exact element={<RequireAuth><Search /></RequireAuth>} />
            <Route path="/trash" exact element={<RequireAuth><Trash /></RequireAuth>} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/edit/:id" element={<RequireAuth><QuillEditor /></RequireAuth>} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </div>
  );
};


export default App;
