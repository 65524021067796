import React, { useEffect } from "react";

// 引入自定义Hooks以及请求模块
import { formatTime } from "../utils";
import { delDoc } from "../services/http";
import useShowModal from "../hooks/useShowModal";
import useListHandle from "../hooks/useListHandle";
import useTableData from "../hooks/useTableData";

// 第三方二维码组件
import QRCode from 'qrcode.react';

// 引入antd组件
import InfiniteScroll from "react-infinite-scroll-component";
import { Table, Tabs, Button, Menu, Dropdown, message, Modal, Input, Row, Col, Skeleton, List, Avatar } from 'antd';
import { MoreOutlined, FolderOpenOutlined, DeleteOutlined, ShareAltOutlined, CopyTwoTone, SaveTwoTone, UserOutlined } from '@ant-design/icons';

import '../assets/css/Home.css'
const { TabPane } = Tabs;

/**
 * 我的文档页
 * @returns 
 */
const Self = () => {
  // custom-hooks
  const { docInfo, isModalVisible, copyUrl, showModal, handleOk, handleCancel, changeCanvasToPic } = useShowModal();
  const { selectedRowKeys, setSelectedRowKeys, handleGoToEdit, onSelectChange } = useListHandle()
  const { page, size, total, docList, loading, setLoading, setPage, setSize, handleGetList } = useTableData()

  const hasSelected = selectedRowKeys.length > 0;

  /**
 *  加载更多
 * @returns 
 */
  const loadMore = () => {
    if (loading) return;
    handleGetList(page + 1, size, '0')
  }


  /**
   * 删除文档
   * @param {*} article_id 
   */
  const handleDelDoc = (article_id) => {
    const res = delDoc({ article_id });
    res.then((res) => {
      if (res.code == 0) {
        message.success('删除成功')
        handleGetList(1, size, '0')
      }
    });
  }

  /**
 * 批量删除文档
 */
  const batchDelete = () => {
    setLoading(true);
    const list = selectedRowKeys.map(item => docList[item].article_id)
    const res = delDoc({ article_id: list });
    res.then((res) => {
      if (res.code == 0) {
        message.success('删除成功')
        handleGetList(1, size, '0')
      }
      setLoading(false)
      setSelectedRowKeys([])
    });
  };

  /**
 * 切换页面刷新列表
 * @param {*} current 
 * @param {*} size 
 */
  const showSizeChange = (current, size) => {
    setPage(current)
    setSize(size)
    handleGetList(current, size, '0')
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'doc_name',
      key: 'doc_name',
      render: (text) =>
        <div
        >
          {text}
        </div>,
      onCell: (columns, index) => {
        return {
          onClick: (e) => {
            handleGoToEdit(columns.doc_id)
          },
        };
      }
    },
    {
      title: '来自',
      dataIndex: 'nickname',
      key: 'nickname',
      render: (cur, row, index) => {
        return row.uid == row.selfid ? '我' : cur
      }
    },
    {
      title: '最近查看',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <MoreOutlined />
        </Dropdown>
      )
    }
  ];

  const menu = (record) => (
    <Menu>
      <Menu.Item key='1' onClick={() => {
        handleGoToEdit(record.doc_id)
      }}>
        <FolderOpenOutlined /> 打开文档
      </Menu.Item>
      <Menu.Item key='2' onClick={() => {
        showModal(record)
      }}>
        <ShareAltOutlined /> 分享文档
      </Menu.Item>
      <Menu.Item key='3' onClick={() => {
        handleDelDoc(record.article_id)
      }}>
        <DeleteOutlined /> 删除文档
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    handleGetList(page, size, '0')
  }, [])

  return (
    <div className="self">
      <a id="down_link"></a>
      <Modal title="分享" visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
        <div className="modal">
          <QRCode value={`${window.location.origin}/#/login?redirect=${docInfo.doc_id}`} id="qrcode" />
          <Input className="urlpath" disabled value={`${window.location.origin}/#/login?redirect=${docInfo.doc_id}`} />
          <div className="btn-list">
            <Button icon={<CopyTwoTone />} onClick={copyUrl}>复制链接</Button>
            <Button icon={<SaveTwoTone />} onClick={changeCanvasToPic}>转存二维码</Button>
          </div>
        </div>
      </Modal>
      <Row gutter={24}>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 24 }}>
          <Tabs defaultActiveKey="1" size="large">
            <TabPane tab="我的文档" key="1">
              <div style={{ marginBottom: 5, display: selectedRowKeys.length ? 'block' : 'none', float: 'left' }}>
                <Button onClick={batchDelete} disabled={!hasSelected} loading={loading}>
                  批量删除
                </Button>
              </div>
              <Table rowSelection={rowSelection} columns={columns} dataSource={docList} pagination={{ defaultCurrent: page, total: total, pageSizeOptions: [10, 20, 50, 100], onChange: showSizeChange, position: ['bottomCenter'] }}
              />
            </TabPane>
          </Tabs>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 0 }}>
          <Tabs defaultActiveKey="1" size="large">
            <TabPane tab="我的文档" key="1">
              <InfiniteScroll
                dataLength={docList.length}
                next={loadMore}
                hasMore={docList.length < total}
                height={500}
                loader={<Skeleton avatar paragraph={{ rows: 2 }} active />}
              >
                <List
                  dataSource={docList}
                  renderItem={item => (
                    <List.Item key={item.article_id}>
                      <List.Item.Meta
                        avatar={<Avatar src={item.avatar && item.avatar} shape="square" size="primary" icon={<UserOutlined />} />}
                        title={<a onClick={(e) => {
                          e.preventDefault();
                          handleGoToEdit(item.doc_id)
                        }}>{item.doc_name}</a>}
                        description={
                          <div><span>我</span> <span>{formatTime(item.updatedAt)}</span></div>
                        }
                      />
                      <div><Dropdown overlay={menu(item)} trigger={['click']}>
                        <MoreOutlined />
                      </Dropdown></div>
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </div>
  )
}

export default Self;