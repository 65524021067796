import React, { useEffect } from "react";

// 引入自定义Hooks以及请求模块
import { restoreDoc } from "../services/http";
import useListHandle from "../hooks/useListHandle";
import useTableData from "../hooks/useTableData";

// 引入antd组件
import { Table, Tabs, Button, Menu, Dropdown, message } from 'antd';
import { MoreOutlined, DeleteOutlined } from '@ant-design/icons';
import '../assets/css/Home.css'
const { TabPane } = Tabs;

// 回收站页
const Trash = () => {
  // custom-hooks
  const { selectedRowKeys,  setSelectedRowKeys, onSelectChange } = useListHandle()
  const { page, size, total, docList, loading, setDocList, setLoading, setPage, setSize, handleGetList } = useTableData()

  const hasSelected = selectedRowKeys.length > 0;

  /**
   * 还原文档
   * @param {*} article_id 
   */
  const handleRestoreDoc = (article_id) => {
    const res = restoreDoc({ article_id });
    res.then(res => {
      if (res.code == 0) {
        message.success('恢复成功')
        handleGetList(page, size, '2')
      }
    })
  }

  /**
   * 批量还原 
   */
  const batchRestore = () => {
    setLoading(true);
    const list = selectedRowKeys.map(item => docList[item].article_id)
    const res = restoreDoc({ article_id: list });
    res.then(res => {
      if (res.code == 0) {
        message.success('还原成功')
        handleGetList(page, size, '2')
      }
      setLoading(false)
      setSelectedRowKeys([])
    })
  };

  /**
   * 切换页面刷新列表
   * @param {*} current 
   * @param {*} size 
   */
  const showSizeChange = (current, size) => {
    setPage(current)
    setSize(size)
    handleGetList(current, size, '2')
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'doc_name',
      key: 'doc_name',
      render: (text) =>
        <div
        >
          {text}
        </div>,
    },
    {
      title: '来自',
      dataIndex: 'nickname',
      key: 'nickname',
      render: (cur, row, index) => {
        return '我'
      }
    },
    {
      title: '最近查看',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <MoreOutlined />
        </Dropdown>
      )
    }
  ];

  const menu = (record) => (
    <Menu>
      <Menu.Item key='1' onClick={() => {
        handleRestoreDoc(record.article_id)
      }}>
        <DeleteOutlined /> 还原文档
      </Menu.Item>
    </Menu>
  )

  useEffect(() => {
    handleGetList(page, size, '2')
    return () => {
      setDocList([])
    }
  }, [])

  return (
    <div className="trash">
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab="回收站" key="1">
          <div style={{ marginBottom: 5, display: selectedRowKeys.length ? 'block' : 'none', float: 'left' }}>
            <Button onClick={batchRestore} disabled={!hasSelected} loading={loading}>
              批量还原
            </Button>
          </div>
          <Table rowSelection={rowSelection} columns={columns} dataSource={docList}
            pagination={{ defaultCurrent: page, total: total, pageSizeOptions: [10, 20, 50, 100], onShowSizeChange: showSizeChange, onChange: showSizeChange, position: ['bottomCenter'] }}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Trash;