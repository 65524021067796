import { useEffect } from "react";

const useCheckOnline = (setLoading, setReadOnly, readRef, Socket) => {
  const updateNetwork = () => {
    if (!navigator.onLine) {
      setLoading(true)
      setReadOnly(true)
      readRef.current = true
      document.body.style.overflow = 'hidden';
      return;
    }
    setLoading(false)
    setReadOnly(false)
    document.body.style.overflow = '';
    !Socket.current.socket && Socket.current.reconnect()
    readRef.current = false
  };

  useEffect(() => {
    window.addEventListener('online', updateNetwork);
    window.addEventListener('offline', updateNetwork);
    return () => {
      window.removeEventListener("offline", updateNetwork);
      window.removeEventListener("online", updateNetwork);
    }
  }, [])

}

export default useCheckOnline;