import { useRef, useEffect} from 'react';
import { useParams } from "react-router-dom";
import Editor from "../../module/Editor";


const useCreateEditor = (quill, userInfo, Socket) => {
  const match = useParams()
  let editor = useRef(null);

  useEffect(() => {
    editor.current =  new Editor(quill.current, match.id, userInfo, Socket)
    return () => {
      editor.current && editor.current.close()
    }
  }, [])

  return {
    editor
  }
}

export default useCreateEditor;