import React, { useEffect, useState } from 'react';
import { Avatar, Button, Dropdown, Menu, Modal, Input, Upload, message, Row, Col } from 'antd';
import { uuid, getUserInfo as localUserInfo } from '../utils/index'
import { PlusSquareOutlined, UserOutlined, SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { handleChangeText } from '../components/Login'
import { createDoc, uploadImg, getUserInfo, updateUserInfo } from '../services/http';
import useSearch from '../hooks/useSearch';

const TheHeader = () => {
  const navigate = useNavigate()
  const users = localUserInfo()
  const [uid, setUid] = useState('')
  const [nickname, setNickname] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [avatar, setAvatar] = useState('')
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { searchText, setSearchText, onKeyDown } = useSearch()

  const goToEditor = () => {
    const newId = uuid()
    const res = createDoc({ doc_id: newId, uid: JSON.parse(localStorage.getItem('userInfo')).user.uid, doc_name: '未命名文档' })

    res.then(res => {
      if (res.code === 0) {
        navigate(`/edit/${newId}`)
      }
    })
  }

  const getInfo = () => {
    const res = getUserInfo({ uid: users.uid })
    res.then(res => {
      if (res.code === 0) {
        setUid(res.result.uid)
        setNickname(res.result.nickname)
        setUsername(res.result.username)
        setAvatar(res.result.avatar)
      }
    })
  }

  const handleUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('仅允许上传JPG/PNG文件格式!');
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('图片大小不能超过5M！');
      return;
    }
    const formData = new FormData()
    formData.append('file', file)
    const res = uploadImg(formData)
    res.then(res => {
      if (res.code === 0) {
        setAvatar(res.result)
      }
    })
    return false;
  }

  const updateUser = () => {
    const res = updateUserInfo({ nickname, password, avatar })
    setLoading(true)
    res.then(res => {
      if (res.code === 0) {
        localStorage.setItem('userInfo', JSON.stringify(res.result))
        setNickname(res.result.user.nickname)
        setAvatar(res.result.user.avatar)
        setLoading(false)
        message.success(res.message)
      }
    })
  }

  const handleSearch = () => {
    navigate(`/search/?keyword=${searchText}`)
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    updateUser()
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setAvatar(users.avatar)
    setIsModalVisible(false);
  };


  const quit = () => {
    localStorage.clear()
    navigate("/login")
  }

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={showModal}>
        个人信息
      </Menu.Item>
      <Menu.Item key="2">
        <a onClick={(e) => {
          e.preventDefault();
          quit()
        }} target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          退出登录
        </a>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    getInfo()
  }, [])

  return (
    <div className='header'>
      <Row>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 24 }} >
          <div style={{
            display: 'flex',
            width: '100%'
          }}>
            <div className='header-left'>
              <Button type="primary" onClick={goToEditor}><PlusSquareOutlined />新建文档</Button>
            </div>
            <div className='header-right'>
              <Dropdown overlay={menu} placement="bottomRight" trigger={'click'}>
                <Avatar style={{ "cursor": "pointer" }} shape="square" size="primary" icon={avatar ? '' : <UserOutlined />} src={avatar} />
              </Dropdown>
            </div>
          </div>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 0 }}>
          <div className='mobile-header'>
            <div className='input-search'>
              <Input onKeyDown={(e) => onKeyDown(e, handleSearch)} value={searchText} onChange={(e) => {
                setSearchText(e.target.value)
              }} placeholder="搜索" prefix={<SearchOutlined />} />
            </div>
            <div className='mobile-right'>
              <Dropdown overlay={menu} placement="bottomRight" trigger={'click'}>
                <Avatar style={{ "cursor": "pointer" }} shape="square" size="primary" icon={avatar ? '' : <UserOutlined />} src={avatar} />
              </Dropdown>
              <div className='mobile-user'>{users.nickname}</div>
            </div>
          </div>
        </Col>
      </Row>

      <Modal title="个人信息" visible={isModalVisible} footer={[
        <Button key="back" onClick={handleCancel}>
          关闭
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          确定修改
        </Button>
      ]} onOk={handleOk} onCancel={handleCancel}>
        <div className='user-info'>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={handleUpload}
          >
            {avatar ? <img src={avatar} alt="avatar" style={{ width: '100%' }} /> : '+'}
          </Upload>
          <div className='user-right'>
            <Input addonBefore="账号" disabled value={username} />
            <Input addonBefore="昵称" value={nickname} placeholder="你的昵称" onChange={(e) => { handleChangeText(e, setNickname) }} />
            <Input.Password addonBefore="密码" value={password} placeholder="请输入修改密码" onChange={(e) => { handleChangeText(e, setPassword) }} />
          </div>
        </div>


      </Modal>
    </div>
  )
}

export default TheHeader;