import React, { useRef, useEffect, useState } from 'react'
import { login, reg } from '../services/http'
import { getUserInfo } from '../utils'
import { message, Form, Input, Button, Checkbox } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import '../assets/css/Login.css'

function handleChangeText(e, func) {
    func(e.target.value)
}

const Login = () => {
  const navigate = useNavigate()
  const userInfo = getUserInfo()
  let [searchParams, setSearchParams] = useSearchParams()

  const [isLogin, setIsLogin] = useState(true)
  const [username, setUsername] = useState('')
  const [nickname, setNickname] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const formRef = useRef()

  const clear = (bool) => {
    formRef.current.setFieldsValue({
      nickname: '',
      username: '',
      password: '',
      password2: ''
    })
    setNickname('')
    setUsername('')
    setPassword('')
    setPassword2('')
    setIsLogin(bool)
  }

  const convertParams = () => {
    let url = searchParams.get("redirect")
    return url;
  }

  const handleSubmit = (e) => {
    if (nickname.trim() === '' || username === '' || password === '' || password2 === '') {
      message.error('缺少必须要参数')
      return;
    }
    if (nickname.length < 2 || username.length < 6 || password.length < 6 || password2.length < 6) {
      message.error('昵称不能小于2位，用户名或密码长度不能小于6位')
      return;
    }
    if (nickname.length > 12 || username.length > 12 || password.length > 12 || password2.length > 12) {
      message.error('昵称、用户名或密码长度不能大于12位')
      return;
    }
    if (password !== password2) {
      message.error('两次密码不一致')
      return;
    }
    const res = reg({
      nickname: nickname,
      username: username,
      password: password,
      password2: password2
    })

    res.then(data => {
      if (data.code == 0) {
        message.success(data.message)
        clear(true)
      }
    })
  }


  const handleLogin = () => {
    if (!username || !password) {
      message.error('请输入用户名和密码')
      return;
    }

    if (username.length < 6 || password.length < 6) {
      message.error('用户名或密码长度不能小于6位')
      return;
    }

    if (username.length > 12 || password.length > 12) {
      message.error('用户名或密码长度不能大于12位')
      return;
    }

    const res = login({
      username: username,
      password: password
    })

    res.then(res => {
      if (res.code === 0) {
        message.success(res.message)
        localStorage.setItem('userInfo', JSON.stringify(res.result))
        let url = convertParams()
        setTimeout(() => {
          if (url) {
            navigate(`/edit/${url}`)
          } else {
            navigate('/')
          }

        }, 400)
      }
    }).catch(err => {
      localStorage.clear()
    })
  }


  useEffect(() => {
    if (userInfo) {
      let url = convertParams()
      if (url) {
        navigate(`/edit/${url}`)
      } else {
        navigate('/')
      }
    }
  })


  return (
    <div className='login'>
      <div className='login-box'>
        <div className='title'>
          <h1>CoWord</h1>
          <h5>一一 一款简单的多人协作文档编辑器</h5>
        </div>
        <Form
          ref={formRef}
          name="basic"
          className='login-form'
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          autoComplete="off"
        >
          {!isLogin ? <Form.Item
            name="nickname"
            rules={[
              {
                required: true,
                message: '请输入昵称',
              },
              { type: 'string', min: 2, max: 12, message: '昵称长度必须在2-12位之间' }
            ]}
          >
            <Input placeholder='昵称' value={nickname} onChange={(e) => { handleChangeText(e, setNickname) }} />
          </Form.Item> : ''}
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: '请输入用户名',
              },
              {
                type: 'string',
                min: 6,
                max: 12,
                message: '用户名长度必须在6-12位之间',
              }
            ]}
          >
            <Input placeholder='用户名' value={username} onChange={(e) => { handleChangeText(e, setUsername) }} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码',
              },
              {
                type: 'string',
                min: 6,
                max: 12,
                message: '密码长度必须在6-12位之间',
              }
            ]}
          >
            <Input.Password placeholder='密码' value={password} onChange={(e) => { handleChangeText(e, setPassword) }} />
          </Form.Item>
          {
            !isLogin ? <Form.Item
              name="password2"
              rules={[
                {
                  required: true,
                  message: '请继续输入密码',
                },
                {
                  type: 'string',
                  min: 6,
                  max: 12,
                  message: '密码长度必须在6-12位之间',
                }
              ]}
            >
              <Input.Password placeholder='确认密码' value={password2} onChange={(e) => { handleChangeText(e, setPassword2) }} />
            </Form.Item> : ''
          }

          <Form.Item>
            {
              isLogin ? <Button type="link" onClick={() => clear(false)}>我要注册</Button> : <Button type="link" onClick={() => clear(true)}>我要登录</Button>
            }

          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            {
              isLogin ? <Button type="primary" style={{ "background": "#6841f5", "border": "#6841f5" }} htmlType="submit" onClick={handleLogin}>
                登录
              </Button> : <Button type="primary" style={{ "background": "#6841f5", "border": "#6841f5" }} htmlType="submit" onClick={handleSubmit}>
                注册
              </Button>
            }


          </Form.Item>
        </Form>
      </div>

      <div className='login-footer'>
        <a className='text-color' href="https://beian.miit.gov.cn/">鄂ICP备2022016605号-1</a>
      </div>
    </div>
  )
}

export {
  Login, handleChangeText
};