import EditorEvents from "./EditorEvents";
class Counter {
  constructor(quill, options) {
    this.editor = null;
    this.quill = quill
    this.options = options
    this.container = document.querySelector(options.container)
  }

  init(editor) {
    if (!this.editor) {
      this.editor = editor
      this.editor.on(EditorEvents.editorTextChanged, () => {

        var length = this.calculate();

        this.container.innerText = `${length} 个字`
      })
    }
  }

  calculate() {
    let text = this.quill.getText()
    let space = [9, 10, 32, 160]
    let length = 0
    let strArr = text.split('')
    for(let i in strArr) {
      let char = strArr[i]
      if(space.includes(char.charCodeAt()) ) continue;
        if(char.charCodeAt() >= 33 && char.charCodeAt() <= 126 && ( space.includes(strArr[Number(i)+1]?.charCodeAt()) || strArr[Number(i)+1]?.charCodeAt() > 127 || strArr[Number(i)+1] == undefined)) {
          ++length;
          continue;
        }
        if(char.charCodeAt() > 127 && char.charCodeAt() != 160) {
          ++length;
        }
    }
    return length;
  }
}


export {
  Counter as
  default
};