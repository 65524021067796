import React, {useState} from "react";

import { Button } from 'antd'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

const Select = (props) => {
  
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = (e) => {
    e.nativeEvent.stopImmediatePropagation();
    document.onclick = () => {
    setIsVisible(false)
    document.onclick = null
  }
    setIsVisible(!isVisible);
  }

  const handleChange = item => {
    props.handleChange(item)
    setIsVisible(false);
  }

  

  return (
    <div className="scale">
      <Button size="small" icon={<MinusOutlined />} onClick={() => props.handleSetPercent(-5, 50)}></Button>
      <div className="c-select">
        <div className="c-selector" onClick={handleClick}>{props.current}%</div>
        <div className={isVisible ? "c-slider slider-show" : "c-slider"}>
          {
            props.percent.map((item, index) => {
              return (
                <div key={index} className={item === props.current ? 'c-slider-item active' : 'c-slider-item'} onClick={() => { handleChange(item) }}>{item}%</div>
              )
            })
          }
        </div>
      </div>
      <Button size="small" icon={<PlusOutlined />} onClick={() => props.handleSetPercent(5, 400)}></Button>
    </div>
  )
}

export default Select;