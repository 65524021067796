import { useState } from "react";
import moment from 'moment'
import { getUserInfo } from "../utils";
import { getDocList } from "../services/http";

const useTableData = () => {
  const userInfo = getUserInfo()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(10)
  const [pages, setPages] = useState(0)
  const [total, setTotal] = useState(0)
  const [docList, setDocList] = useState([]);
  const [loading, setLoading] = useState(false);


  const handleGetList = (page, size, type) => {
    if (!userInfo) return;
    setLoading(true);
    const res = getDocList({ uid: userInfo.uid, doc_type: type, size, page });
    res.then((res) => {
      if (res.code == 0) {
        let data = res.result.data.map((item, index) => {
          return {
            key: index,
            ...item,
            createdAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          }
        })
        setTotal(res.result.total);
        setPage(page);
        setPages(res.result.pages)
        setDocList(page == 1 ? data : [...docList, ...data]);
      }
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }

  return {
    userInfo,
    page,
    size,
    total,
    pages,
    docList,
    loading,
    setDocList,
    setLoading,
    setPage,
    setPages,
    setSize,
    setTotal,
    handleGetList
  }
}

export default useTableData;