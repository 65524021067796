import {
  Delta
} from "rich-text"
import _ from 'lodash'
import EditorEvents from "./EditorEvents"

class Composition {
  constructor(editor) {
    this.editor = editor
    this.quill = editor.quill
    this.cursor = this.quill.getModule('cursors')
    this.synchronizer = null
    this.compositionInProgress = false;

    this.updatePendingDeltas = []
    this.pendingSubmitDeltas = []

    let self = this;
    this.clear()

    this.lastCompositionEndTime = null;
    this.container = document.querySelector('#counter')

    let compositionStatus = false;
    this.quill.root.addEventListener('compositionstart', (event) => {
      compositionStatus = true
      self.compositionInProgress = true
    })

    this.quill.root.addEventListener('compositionend', (event) => {
      compositionStatus = false
      let time = event.timeStamp;
      self.lastCompositionEndTime = time;
      setTimeout(() => {
        if (compositionStatus || time !== self.lastCompositionEndTime || this.editor.userInfo.current.role_status != '0') {
          return;
        }
        self.compositionInProgress = false;
        self.flush()
      }, 0)
    })

    this.quill.on('text-change', (delta, oldDelta, source) => {
      if (source !== 'user' || !navigator.onLine || this.editor.userInfo.current.role_status != '0') {
        return;
      }
      let convertDelta = self.localOnlyDelta.revertDelta.transform(delta, true);
      let convertOldDelta = oldDelta.compose(self.localOnlyDelta.revertDelta);

      self.transformLocalDelta(delta);
      self.submitToServer(convertDelta, convertOldDelta);
    })

    this.quill.on('selection-change', (range, oldRange, source) => {
      var length = this.calculate();
    this.container.innerText = `${length} 个字`
      if (range && !compositionStatus && this.editor.userInfo.current.role_status == '0') {
        navigator.onLine && this.editor.SocketCursor.current.send({
          type: 'CURSOR',
          range: range,
          userInfo: this.editor.userInfo.current
        })
      }
    })
  }

  /**
   * 计算字符数
   * @returns 
   */
   calculate() {
    let text = this.quill.getText()
    let space = [9, 10, 32, 160]
    let length = 0
    let strArr = text.split('')
    for(let i in strArr) {
      let char = strArr[i]
      if(space.includes(char.charCodeAt()) ) continue;
      if(char.charCodeAt() >= 33 && char.charCodeAt() <= 126 && ( space.includes(strArr[Number(i)+1]?.charCodeAt()) || strArr[Number(i)+1]?.charCodeAt() > 127 || strArr[Number(i)+1] == undefined)) {
        ++length;
        continue;
      }
      if(char.charCodeAt() > 127) {
        ++length;
      }
    }
    return length;
  }

  /**
   * 本地的每一次操作都在changeDelta里
   * 还原操作在revertDelta里
   * 本地操作步骤steps
   * 起始步骤
   */
  clear() {
    this.localOnlyDelta = {
      changeDelta: new Delta(),
      revertDelta: new Delta(),
      steps: [],
      originalSteps: []
    }
  }

  flush(oldDelta) {
    let updateDelta = this.composeDeltas(this.updatePendingDeltas)
    let [pendingSubmitDelta, transformPendingSubmitDelta] = this.handleSubmitDeltaMerge(updateDelta)
    let changeDelta = updateDelta.compose(transformPendingSubmitDelta)

    oldDelta = this.handleLocalDeltaMerge(updateDelta, pendingSubmitDelta, transformPendingSubmitDelta, oldDelta)

    this.updatePendingDeltas.length = 0
    this.pendingSubmitDeltas.length = 0

    if (updateDelta.ops.length !== 0) {
      this.editor.emit(EditorEvents.upstreamTextChanged, {
        delta: updateDelta,
        oldDelta: oldDelta
      });
    }
    this.editor.emit(EditorEvents.userTextChanged, {
      delta: transformPendingSubmitDelta,
      oldDelta: oldDelta.compose(updateDelta)
    });
    this.editor.emit(EditorEvents.editorTextChanged, {
      delta: changeDelta,
      oldDelta: oldDelta
    })

  }

  handleSubmitDeltaMerge(updateDelta) {
    let pendingSubmitDelta = this.composeDeltas(this.pendingSubmitDeltas)

    let transformPendingSubmitDelta = updateDelta.transform(pendingSubmitDelta, true)
    if (transformPendingSubmitDelta.ops.length !== 0)
      this.synchronizer.syncSubmitOp(transformPendingSubmitDelta)

    return [pendingSubmitDelta, transformPendingSubmitDelta]

  }

  handleLocalDeltaMerge(updateDelta, localChangeDelta, finalSubmittedDelta, oldDelta) {
    let revertDelta = new Delta();

    if (!oldDelta || (updateDelta && updateDelta.ops.length !== 0)) {
      localChangeDelta.ops.forEach(op => {
        if (op.retain) {
          revertDelta.retain(op.retain)
        } else if (op.insert) {
          revertDelta.delete(op.insert.length)
        } else {
          console.log('can not handle this op')
        }
      })
    }

    if (!oldDelta) {
      let currentContent = this.getEditorContents()
      oldDelta = currentContent.compose(revertDelta)
    }

    if (updateDelta && updateDelta.ops.length !== 0) {
      let localFixingDelta = revertDelta.compose(updateDelta).compose(finalSubmittedDelta)
      this.updateQuill(localFixingDelta, 'silent')
    }

    return oldDelta
  }

  submitLocalFixingDelta(delta) {
    this.updateQuill(delta, "silent");
  }

  updateQuill(delta, source) {
    let convertDelta = this.localOnlyDelta.changeDelta.transform(delta, true);
    this.quill.updateContents(convertDelta, source)

    if (source !== 'user') {
      this.transformLocalDelta(convertDelta)
    }
  }

  getEditorContents() {
    let delta = this.quill.getContents();
    return delta.compose(this.localOnlyDelta.revertDelta);
  }

  composeDeltas(deltaList) {
    let delta = new Delta();
    deltaList.forEach(item => {
      delta = delta.compose(item);
    })
    return delta;
  }

  setSynchronizer(synchronizer) {
    this.synchronizer = synchronizer;
  }

  setEditorContent(delta, source) {
    this.clear()
    this.quill.setContents(delta, source);
    this.editor.emit(EditorEvents.editorTextChanged, {
      delta: delta,
      oldDelta: new Delta().insert("\n")
    });
  }

  submitToServer(delta, oldDelta) {
    this.addPendingSubmitDelta(delta);

    if (!this.isComposing()) {
      this.flush(oldDelta);
    }
  }

  submitToEditor(delta) {
    this.addUpdatePendingDelta(delta)
    if (!this.isComposing()) {
      this.flush();
    }
  }

  addUpdatePendingDelta(delta) {
    this.updatePendingDeltas.push(delta)
  }

  addPendingSubmitDelta(delta) {
    this.pendingSubmitDeltas.push(delta)
  }

  transformLocalDelta(delta) {
    this.localOnlyDelta.steps.forEach(step => {
      step.changeDelta = delta.transform(step.changeDelta, true);
      step.revertDelta = delta.transform(step.revertDelta, true);
    })

    this.updateLocalDelta()
  }

  updateLocalDelta() {
    this.localOnlyDelta.changeDelta = new Delta()
    this.localOnlyDelta.revertDelta = new Delta()

    for (let i = 0, len = this.localOnlyDelta.steps.length; i < len; i++) {
      this.localOnlyDelta.changeDelta = this.localOnlyDelta.changeDelta.compose(this.localOnlyDelta.steps[i].changeDelta)
    }

    for (let i = this.localOnlyDelta.steps.length - 1; i >= 0; i--) {
      this.localOnlyDelta.revertDelta = this.localOnlyDelta.revertDelta.compose(this.localOnlyDelta.steps[i].revertDelta)
    }
  }

  isComposing() {
    return this.compositionInProgress;
  }
}

export default Composition;