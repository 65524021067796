import { saveAs } from "file-saver";
import * as quillToWord from "quill-to-word";

const useExportWord = () => {
  const quillToWordConfig = {
    exportAs: "blob"
  };

  const getBase64Image = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();
    await new Promise((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
    return reader.result
  }

  const newPrint = (message, quill, doc_name) => {
    const delta = quill.current.getContents();
    message('正在生成Word文档中', '请稍后...')
    delta.ops.forEach((item, index) => {
      if (item.insert.image && item.insert.image.indexOf('http') !== -1) {
        getBase64Image(item.insert.image).then((res) => {
          delta.ops[index].insert.image = res
        }).catch(err => {
          console.log(err)
        })
      }
    })
    setTimeout(async () => {
      const docAsBlob = await quillToWord.generateWord(delta, quillToWordConfig);
      saveAs(docAsBlob, `${doc_name}.docx`);
    }, 100)

  };

  return {
    newPrint
  }
}

export default useExportWord;