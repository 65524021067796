import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";


import { getUserInfo } from "../utils";
import { searchDoc, delDoc } from "../services/http";
import useShowModal from '../hooks/useShowModal';
import useListHandle from "../hooks/useListHandle";
import useTableData from "../hooks/useTableData";

import moment from 'moment'
import QRCode from 'qrcode.react';

import { Table, Tabs, Button, Menu, Dropdown, message, Modal, Input } from 'antd';
import { MoreOutlined, FolderOpenOutlined, DeleteOutlined, ShareAltOutlined, CopyTwoTone, SaveTwoTone } from '@ant-design/icons';
import '../assets/css/Search.css'
const { TabPane } = Tabs;

const Search = () => {

  const navigate = useNavigate()
  let [searchParams, setSearchParams] = useSearchParams()

  const userInfo = getUserInfo()
  let [search, setSearch] = useState('')

  // custom-hooks
  const { docInfo, isModalVisible, copyUrl, showModal, handleOk, handleCancel, changeCanvasToPic } = useShowModal();
  const { selectedRowKeys, loading, setSelectedRowKeys, setLoading, handleGoToEdit, onSelectChange } = useListHandle()
  const { docList, page, size, total, setPage, setSize, setTotal, setDocList } = useTableData()

  const handleSearchList = (doc_name, page, size) => {
    if (!userInfo) return;
    const res = searchDoc({ uid: userInfo.uid, doc_name, size, page });
    res.then((res) => {
      if (res.code == 0) {
        let data = res.result.data.map((item, index) => {
          return {
            key: index,
            ...item,
            createdAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            updatedAt: moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
          }
        })
        setTotal(res.result.total)
        setDocList(docList => docList = data);
      }
    });
  }

  const handleDelDoc = (article_id) => {
    const res = delDoc({ article_id });
    res.then((res) => {
      if (res.code == 0) {
        message.success('删除成功')
        handleSearchList(search, page, size)
      }
    });
  }

  const batchDelete = () => {
    setLoading(true);
    const list = selectedRowKeys.map(item => docList[item].article_id)
    const res = delDoc({ article_id: list });
    res.then((res) => {
      if (res.code == 0) {
        message.success('删除成功')
        handleSearchList(search, page, size)
      }
      setLoading(false)
      setSelectedRowKeys([])
    });
  };


  const rowSelection = {
    selectedRowKeys,
    getCheckboxProps: (record) => ({
      disabled: record.doc_status == '2' || record.uid != record.selfid
    }),
    onChange: onSelectChange,
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'doc_name',
      key: 'doc_name',
      render: (text, record) =>
        <div
          style={{ textDecoration: record.doc_status == '2' ? 'line-through' : 'normal' }}
        >
          <span dangerouslySetInnerHTML={{ __html: `<span>${text.replace(search, '<span class="search-keyword">' + search + '</span>')}</span>` }}></span>
          <span style={{ fontSize: '12px', color: '#a9a9a9', fontStyle: 'italic' }}>{record.doc_status == '2' ? '(已删除)' : ''}</span>
        </div>,
      onCell: (columns, index) => {
        return {
          onClick: (e) => {
            columns.doc_status != '2' && handleGoToEdit(columns.doc_id)
          },
        };
      }
    },
    {
      title: '来自',
      dataIndex: 'nickname',
      key: 'nickname',
      render: (cur, row, index) => {
        return row.uid == row.selfid ? '我' : cur
      }
    },
    {
      title: '最近查看',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Dropdown overlay={menu(record)} trigger={['click']}>
          <MoreOutlined />
        </Dropdown>
      )
    }
  ];


  const menu = (record) => (
    <Menu>
      {
        record.doc_status != '2' ? <Menu.Item key='1' onClick={() => {
          handleGoToEdit(record.doc_id)
        }}>
          <FolderOpenOutlined /> 打开文档
        </Menu.Item> : ''
      }
      {
        record.doc_status == '0' ? <Menu.Item key='2' onClick={() => {
          showModal(record)
        }}>
          <ShareAltOutlined /> 分享文档
        </Menu.Item> : ''
      }
      {
        record.uid == record.selfid && record.doc_status == '0' ? <Menu.Item key='3' onClick={() => {
          handleDelDoc(record.article_id)
        }}>
          <DeleteOutlined /> 删除文档
        </Menu.Item> : ''
      }

    </Menu>
  )

  const hasSelected = selectedRowKeys.length > 0;

  const showSizeChange = (current, size) => {
    setPage(current)
    setSize(size)
    handleSearchList(search, current, size)
  }

  useEffect(() => {
    let isRequest = false
    if (!searchParams.get('keyword')) {
      navigate('/')
      return;
    }
    setSearch(searchParams.get('keyword'))
    !isRequest && handleSearchList(searchParams.get('keyword'), page, size)
    return () => {
      isRequest = true
      setSearch('')
      setDocList([])
    }
  }, [searchParams])

  return <div className="search-page">
    <a id="down_link"></a>
    <Modal title="分享" visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
      <div className="modal">
        <QRCode value={`${window.location.origin}/#/login?redirect=${docInfo.doc_id}`} id="qrcode" />
        <Input className="urlpath" disabled value={`${window.location.origin}/#/login?redirect=${docInfo.doc_id}`} />
        <div className="btn-list">
          <Button icon={<CopyTwoTone />} onClick={copyUrl}>复制链接</Button>
          <Button icon={<SaveTwoTone />} onClick={changeCanvasToPic}>转存二维码</Button>
        </div>
      </div>
    </Modal>
    <Tabs size="large" defaultActiveKey="1">
      <TabPane tab="搜索结果" key="1">
        <div style={{ marginBottom: 5, display: selectedRowKeys.length ? 'block' : 'none', float: 'left' }}>
          <Button onClick={batchDelete} disabled={!hasSelected} loading={loading}>
            批量删除
          </Button>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={docList} pagination={{ defaultCurrent: page, total: total, pageSizeOptions: [10, 20, 50, 100], onShowSizeChange: showSizeChange, onChange: showSizeChange, position: ['bottomCenter'] }}
        />
      </TabPane>
    </Tabs>
  </div>
}

export default Search;