import React, { createRef, useEffect, useState } from "react";
import '../../assets/css/DivContent.css'
import { saveRoomName } from '../../services/http'
import { message } from "antd";

const DivContentEdit = (props) => {
  const [text, setText] = useState("")
  const [status, setStatus] = useState(false)

  const handleInput = (e) => {
    setText(e.target.value)
  }

  const handleSave = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur()
    }
  }

  const handlSaveRoom = () => {
    if (text == props.docInfo.doc_name || props.docInfo.role_status != '0') return;
    const res = saveRoomName({ article_id: props.docInfo.article_id, doc_name: text })
    res.then(res => {
      if (res.code === 0) {
        props.docInfo.doc_name = text
        props.Socket.send({ type: 'CHANGE', title: text })
        message.success(res.message)
      }
    })
  }

  useEffect(() => {
    if (props.Socket) {
      props.Socket.on('CHANGE', (data) => {
        setText(data.title)
        props.docInfo.doc_name = data.title
      })
    }
    if (props.docInfo) {
      setStatus(props.docInfo.role_status == '0' ? false : 'disabled')
      setText(props.docInfo.doc_name)
    }

  }, [
    props.Socket, props.docInfo
  ])

  return (
    <input type="text" disabled={status} value={text || ''} className="room-name" onKeyDown={handleSave} onChange={handleInput} onBlur={handlSaveRoom} />
  )
}

export default DivContentEdit;