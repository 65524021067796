import React, { useEffect } from 'react';
import {Avatar, Menu, Dropdown } from 'antd';
import { UserOutlined, MoreOutlined, CloseOutlined } from '@ant-design/icons';

const UserSidebar = (props) => {
  const userMenu = (item) => (
    <Menu>
      <Menu.Item key="1">
        <div onClick={() => {
          props.Socket.current.send({ type: "ROLE", data: { role_status: item.role_status === 0 ? 1 : 0, article_id: props.docInfo.article_id, uid: item.uid } })
        }}>
          设置{item.role_status === 0 ? '可读' : '可写'}
        </div>
      </Menu.Item>
    </Menu>
  );
  
  return (
    <div className={props.showSidebar ? 'online-list translate' : 'online-list'}>
      <div className="online-item online-header">
        <div className="item-title">当前在线用户数:{props.users.length}</div>
        <div className="item-quit" onClick={props.controlUserShowHide}><CloseOutlined /></div>
      </div>
      {
        props.users.map((item) => {
          return (
            <div className="online-item" key={item.uid}>
              <Avatar src={item.avatar} icon={<UserOutlined />} />
              <div className="user-name">
                <div>{item.nickname}</div>
                <div className="cur-status">状态:{item.role_status == '1' ? '可读' : '可写'}</div>
              </div>
              <div style={{ "width": "50px" }}>
                {
                  props.userInfo.current.uid == props.docInfo.uid ? (
                    item.uid == props.userInfo.current.uid ? '我' : <Dropdown className="drop" overlay={userMenu(item)} placement="bottom">
                      <MoreOutlined />
                    </Dropdown>
                  ) : (item.uid == props.docInfo.uid ? '房主' : (item.uid == props.userInfo.current.uid ? '我' : ''))
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default UserSidebar;