const BASEURL = "101.43.52.6";
const HTTP_PORT = '8000'
const DB_PORT = '8081'
const WS_PORT = '8082'

export default {
  BASEURL,
  HTTP_PORT,
  DB_PORT,
  WS_PORT,
}