import React, { useEffect, useRef, useState } from "react";

import { Button } from "antd";
import { FullscreenOutlined, FullscreenExitOutlined, ReloadOutlined } from '@ant-design/icons';
import Select from "../../components/Select";
import '../../assets/css/Footer.css'
import useSyncCallback from "./useSyncCallback";
import usePrevious from "../../hooks/usePrevious";
const { Option } = Select;

const Footer = () => {
  const isFull = useRef(false)
  const editor = document.querySelector('#quill-editor')
  const container = document.querySelector('.quill-container')

  const [current, setCurrent] = useState(100)
  const [fullscreen, setFullscreen] = useState(false)
  const [percent, setPercent] = useState([50, 75, 100, 125, 150, 200, 300, 400])

  const preCurrent = usePrevious(current)

  const handleSetMatrix = (value) => {
    return value >= 100 ? `matrix(${Number(value) / 100}, 0, 0, ${Number(value) / 100}, ${19.8433 * ((Number(value) - 100) / 5)}, ${(28.0633 * ((Number(value) - 100) / 5))})` :
      `matrix(${Number(value) / 100}, 0, 0, ${Number(value) / 100}, ${-(19.8433 * ((100 - Number(value)) / 5))}, ${-(28.0633 * ((100 - Number(value)) / 5))})`;
  }

  const handleSetWh = () => {
    if (!container) return;
    let temp;
    let width = Number(container.style.width.split('px')[0]);
    let top = Number(editor.style.top.split('px')[0]);
    let max = (current - preCurrent) / 5;
    let min = (preCurrent - current) / 5;
    temp = current == 100 ? ['809.663px', '0px'] : (current > preCurrent ? [`${width + max * 39.68}px`, `${top + max * 47}px`] :
      [`${width - (min * 39.68)}px`, `${top - min * 47}px`]);
    container.style.width = temp[0];
    editor.style.top = temp[1]
  }
  const handleSetPercent = (num, limit) => {
    let value = current + num;
    if ((limit == 50 && limit > value) || (limit == 400 && value > 400)) return;
    editor.style.transform = handleSetMatrix(value);
    setCurrent(value)
  }

  const handleChange = (value) => {
    editor.style.transform = handleSetMatrix(value);
    setCurrent(value)
  }

  const handleReload = () => {
    setCurrent(100)
    editor.style.transform = 'matrix(1, 0, 0, 1, 0, 0)'
  }


  const handleFullScreen = () => {
    var el = document.documentElement;
    el.requestFullscreen || el.mozRequestFullScreen || el.webkitRequestFullscreen || el.msRequestFullScreen ?
      el.requestFullscreen() || el.mozRequestFullScreen() || el.webkitRequestFullscreen() || el.msRequestFullscreen() : (
        typeof window.ActiveXObject !== 'undefined' ? new ActiveXObject('WScript.Shell') && new ActiveXObject('WScript.Shell').SendKeys('{F11}') : null
      );
    setFullscreen(fullscreen => true);
  }

  const handleQuitScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen || document.mozCancelFullScreen || document.webkitExitFullscreen || document.msExitFullscreen ?
        document.exitFullscreen() || document.mozCancelFullScreen() || document.webkitExitFullscreen() || document.msExitFullscreen() : (
          typeof window.ActiveXObject !== 'undefined' ? new ActiveXObject('WScript.Shell') && new ActiveXObject('WScript.Shell').SendKeys('{F11}') : null
        );
    }
    setFullscreen(fullscreen => false);
  }

  const fullScreenChange = event => {
    if (!document.fullscreenElement)
      setFullscreen(fullscreen => false);
  }

  const init = () => {
    window.addEventListener('keydown', (event) => {
      event = event || window.event;
      if (event.keyCode === 122) {
        event.preventDefault();
        !isFull.current ? handleFullScreen() : handleQuitScreen();
      }
    }, true)

    window.addEventListener('fullscreenchange', fullScreenChange)
  }

  useEffect(() => {
    handleSetWh();
  }, [current])

  useEffect(() => {
    isFull.current = fullscreen
  }, [fullscreen])

  useEffect(() => {
    init()

    return () => {
      window.addEventListener('keydown', null)
    }
  }, [])

  return (
    <div className="edit-footer">
      <div className="footer-content">
        <div className="footer-left">
          <div id="counter"></div>
        </div>
        <div className="footer-right">
          <Select percent={percent} current={current} handleSetPercent={handleSetPercent} handleChange={handleChange}></Select>
          {
            fullscreen ?
              <Button className="full-screen" onClick={handleQuitScreen} icon={<FullscreenExitOutlined />} title="取消全屏"></Button>
              : <Button className="full-screen" onClick={handleFullScreen} icon={<FullscreenOutlined />} title="全屏"></Button>
          }
          <Button className="full-screen" onClick={handleReload} icon={<ReloadOutlined />} title="恢复到100%"></Button>
        </div>
      </div>
    </div>
  )
}

export default Footer;