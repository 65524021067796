import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// 引入工具包模块
import WsClient from "../utils/WsUtils";
import { getUserInfo, formatTime } from '../utils/index'

// 引入第三方模块
import moment from 'moment'
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import '../assets/css/QuillEditor.css'

// 引入自定义Hooks与组件模块
import Footer from './module/Footer'
import Toolbar from "./module/Toolbar";
import UserSidebar from "./module/UserSidebar";
import useShowModal from "../hooks/useShowModal";
import EditorEvents from "../module/EditorEvents";
import DivContentEdit from "./module/DivContentEdit";
import useCheckOnline from "../hooks/useCheckOnline";
import useSyncCallback from "./module/useSyncCallback";
import useExportPdf from "../hooks/export/useExportPdf";
import useShowHide from "../hooks/showModal/useShowHide";
import useExportWord from "../hooks/export/useExportWord";
import useCreateRole from "../hooks/editor/useCreateRole";
import useCreatQuill from "../hooks/editor/useCreateQuill";
import useCreateEditor from "../hooks/editor/useCreateEditor";
import { getDoc, uploadImg, reportUpdate } from "../services/http";

// 引入UI组件库模块
import { Result, Spin, Avatar, Button, Upload, Row, Col, Menu, Dropdown, notification, Modal, Input, BackTop } from 'antd';
import { UserOutlined, UsergroupAddOutlined, HomeFilled, SaveTwoTone, FilePdfTwoTone, FileWordTwoTone, MenuOutlined, CopyTwoTone, ShareAltOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;

/**
 * 编辑页
 * @returns 
 */
const QuillEditor = () => {
  // react-hooks
  const match = useParams()
  const navigate = useNavigate()

  // ref and state
  const Socket = useRef(null)
  const userInfo = useRef(getUserInfo())
  const [users, setUsers] = useState([])
  const [docInfo, setDocInfo] = useState({});
  const [loading, setLoading] = useState(false);

  // custom hooks
  const { newPrint } = useExportWord()
  const { htmlToPdf } = useExportPdf()
  const { changeCanvasToPic } = useShowModal()
  const { readOnly, readRef, setReadOnly, handlerImg } = useCreateRole()
  const { quill, quillRef } = useCreatQuill(handlerImg)
  const { editor } = useCreateEditor(quill, userInfo, Socket)
  const { isModalVisible, isUser, isExist,
    showSidebar, copyUrl, controlUserShowHide,
    setIsExist, showModal, handleOk, handleCancel,
    showUser, handleUserOk, handleUserCancel } = useShowHide()
  useCheckOnline(setLoading, setReadOnly, readRef, Socket)
  /**
   * Socket通知更新在线用户列表
   * @param {*} usersList 
   */
  const handlerUpdateUsers = (usersList) => {
    setUsers([...usersList]);
  }

  /**
   * Socket通知更新用户权限
   * @param {*} role 
   */
  const handlerSetRole = (role) => {
    setReadOnly(role == '0' ? false : true)
    handleReportUpdate()
  }

  const props = {
    showUploadList: false,
    beforeUpload: file => {
      handleUpload(file)
      return false;
    },
  };

  /**
   * 编辑器上传图片
   * @param {*} file 
   */
  const handleUpload = (file) => {
    const formData = new FormData()
    formData.append('file', file)
    const res = uploadImg(formData)
    res.then(res => {
      if (res.code === 0) {
        let length = quill.current.getSelection().index;
        quill.current.insertEmbed(length, 'image', res.result, 'user')
        quill.current.setSelection(length + 1);
      }
    })
  }

  /**
   * 返回首页
   */
  const goToHome = () => {
    Socket.current && Socket.current.closeAll()
    navigate('/')
  }

  /**
   * 通知组件
   * @param {*} title 
   * @param {*} desc 
   */
  const openNotification = (title, desc) => {
    notification.info({
      message: title,
      description: desc,
      duration: 1.5
    });
  };

  /**
   * 同步获取最新State值进行内容更新
   */
  const handleReportUpdate = useSyncCallback(() => {
    const res = reportUpdate({ article_id: docInfo.article_id, doc_name: docInfo.doc_name })
    res.then(res => {
      setDocInfo(res.result)
    })
  })

  const menu = (
    <Menu>
      <SubMenu title="导出为" key="export">
        <Menu.Item key="word" onClick={() => newPrint(openNotification, quill, docInfo.doc_name)}>本地Word文档(.docx)</Menu.Item>
        <Menu.Item key="pdf" onClick={() => htmlToPdf(openNotification, docInfo.doc_name)}>PDF</Menu.Item>
      </SubMenu>
      <Menu.Item key="print">
        <ReactToPrint
          trigger={() => <div>打印 <span style={{ color: '#a6a6a6' }}>(Ctrl+P)</span></div>}
          content={() => quillRef.current}
        />
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    let isUnmount = false;
    /**
     * 获取文档信息以及后续逻辑操作
     */
    async function getData() {
      const res = await getDoc(match.id)
      if (res.code === 0 && !isUnmount) {
        setReadOnly(res.result.role_status == '0' ? false : true)
        setDocInfo(res.result)
        userInfo.current.role_status = res.result.role_status
        Socket.current = new WsClient(userInfo,
          handlerUpdateUsers, res.result.doc_id, handlerSetRole, editor, setLoading, goToHome);
      } else {
        setIsExist(false)
      }
    }
    getData()
    return () => {
      isUnmount = true
      Socket.current?.socket && Socket.current.close();
    }
  }, [
  ]);

  useEffect(() => {
    document.oncontextmenu = function (e) {
      e = e || window.event;
      return false;
    };
    readRef.current = readOnly
    quill.current && quill.current.enable(!readOnly)
    editor.current.on(EditorEvents.userTextSave, () => {
      handleReportUpdate()
    })
  }, [
    readOnly
  ])

  return (
    isExist ?
      <div id="quill">
        <BackTop />
        <Spin tip="断线重连中..." spinning={loading}>
          <a id="down_link"></a>
          <Modal title="个人信息" visible={isUser} footer={null} onOk={handleUserOk} onCancel={handleUserCancel}>
            <div className='user-info'>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                disabled
              >
                {userInfo.current.avatar ? <img src={userInfo.current.avatar} alt="avatar" style={{ width: '100%' }} /> : '+'}
              </Upload>
              <div className='user-right'>
                <Input addonBefore="账号" disabled value={userInfo.current.username} />
                <Input addonBefore="昵称" disabled value={userInfo.current.nickname} />
              </div>
            </div>
          </Modal>
          <Modal title="分享" visible={isModalVisible} footer={null} onOk={handleOk} onCancel={handleCancel}>
            <div className="modal">
              <QRCode value={`${window.location.origin}/#/login?redirect=${window.location.hash.split('#/edit/')[1]}`} id="qrcode" />
              <Input className="urlpath" disabled value={`${window.location.origin}/#/login?redirect=${window.location.hash.split('#/edit/')[1]}`} />
              <div className="btn-list">
                <Button icon={<CopyTwoTone />} onClick={copyUrl}>复制链接</Button>
                <Button icon={<SaveTwoTone />} onClick={changeCanvasToPic}>转存二维码</Button>
              </div>
              <div className="btn-list">
                <Button icon={<FilePdfTwoTone />} onClick={() => htmlToPdf(openNotification, docInfo.doc_name)}>转为PDF</Button>
                <Button icon={<FileWordTwoTone />} onClick={() => newPrint(openNotification, quill, docInfo.doc_name)}>转为WORD</Button>
              </div>
            </div>
          </Modal>
          <div className="quill-header">
            <div id="error"></div>
            <div className="quill-header-left">
              <HomeFilled className="menu-item" onClick={goToHome} />
              <div className="header-info">
                <DivContentEdit docInfo={docInfo} Socket={Socket.current}></DivContentEdit>
                <div className="save-time">{docInfo.updatedAt ? `最近保存时间 ${formatTime(moment(docInfo.updatedAt).format("YYYY-MM-DD HH:mm:ss"))}` : ''}</div>
              </div>
            </div>
            <div className="quill-header-right">
              <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                <MenuOutlined className="menu-item" />
              </Dropdown>

              <UsergroupAddOutlined onClick={controlUserShowHide} className="menu-item" />
              <Button type="primary" shape="circle" onClick={showModal} icon={<ShareAltOutlined />} />
              <Avatar style={{ 'cursor': 'pointer' }} onClick={showUser} className="" icon={userInfo.current.avatar ? '' : <UserOutlined />} size="large" src={userInfo.current.avatar} />
            </div>
          </div>
          <Toolbar></Toolbar>
          <Upload {...props}>
          </Upload>
          <Row>
            <Col span="24">
              <div className="scrollable">
                <div className="drawer"></div>
                <div className="quill-container" style={{ width: '809.663px', minHeight: '1159.53px' }}>
                  <div style={{
                    minHeight: '100%',
                    width: '100%',
                    height: '100%',
                    position: 'relative'
                  }}>
                    <div id="quill-editor" ref={quillRef} style={{top: '0px' }}></div>
                  </div>
                </div>

                <div className="chat"></div>
                <UserSidebar Socket={Socket} docInfo={docInfo} userInfo={userInfo} showSidebar={showSidebar} users={users} controlUserShowHide={controlUserShowHide}></UserSidebar>
              </div></Col>
          </Row>
          <Footer></Footer>
        </Spin>
      </div>
      : <Result
        status="404"
        title="404"
        subTitle="文档不存在"
        extra={<Button type="primary" onClick={goToHome}>返回首页</Button>}
      />
  );
};

export default QuillEditor;