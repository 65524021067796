const EditorEvents = {
    editorTextChanged: "editor-text-changed", // 编辑器内容变化
    userTextChanged: "user-text-changed", // 用户输入内容变化
    userTextSave: "user-text-save",
    upstreamTextChanged: "upstream-text-changed", // 远程更新内容变化
    documentLoaded: "document-loaded", // 文档加载完成
    documentDeleted: "document-deleted", // 文档被删除
    beforeSync: "before-sync", // 同步前
    beforeSubmitToUpstream: "before-submit-to-upstream", // 提交到远程前
    synchronizationError: "synchronization-error", // 同步错误
    imageSkipped: "image-skipped", // 图片跳过
    undo: "undo", // 撤销
    redo: "redo", // 回退
    changeTitle: "change-title", // 改变标题
};

export default EditorEvents;
