import {
  message
} from 'antd';
import codeHandler from './codeHandler';
import config from '../utils/config';

// 'http://101.43.52.6:8000'
const BASEURL = `http://${config.BASEURL}:${config.HTTP_PORT}`;

const checkStatus = res => {
  if (200 >= res.status < 300) {
    return res;
  }
  message.error(`网络请求失败,${res.status}`);
  const error = new Error(res.statusText);
  error.response = response;
  throw error;
};

/**
 *  捕获成功登录过期状态码等
 * @param res
 * @returns {*}
 */
const judgeOkState = async res => {
  const cloneRes = await res.clone().json();
  //TODO:可以在这里管控全局请求
  if (cloneRes.code > 1000) {
    message.error(`${cloneRes.message}`);
    if(codeHandler(cloneRes.code)) {
      localStorage.clear()
      window.location.reload()
    }
  }
  return res;
};

/**
 * 捕获失败
 * @param error
 */
const handleError = error => {
  if (error instanceof TypeError) {
    message.error(`网络请求失败啦！${error}`);
  }
  return { //防止页面崩溃，因为每个接口都有判断res.code以及data
    code: -1,
    data: false,
  };
};

class http {
  /**
   *静态的fetch请求通用方法
   * @param url
   * @param options
   * @returns {Promise<unknown>}
   */
  static async staticFetch(url = '', options = {}) {
    url = BASEURL + url;
    const defaultOptions = {
      /*允许携带cookies*/
      // credentials: 'include',
      /*允许跨域**/
      mode: 'cors',
      headers: {
        Authorization: null,
        // 当请求方法是POST，如果不指定content-type是其他类型的话，默认为如下↓，要求参数传递样式为 key1=value1&key2=value2，但实际场景以json为多
        // 'content-type': 'application/x-www-form-urlencoded',
      },
    };
    if (options.method === 'POST' || 'PUT') {
      defaultOptions.headers['Content-Type'] = 'application/json; charset=utf-8';
    }

    const newOptions = {
      ...defaultOptions,
      ...options
    };
    return fetch(url, newOptions)
      .then(checkStatus)
      .then(judgeOkState)
      .then(res => res.json())
      .catch(handleError);
  }

  /**
   *post请求方式
   * @param url
   * @returns {Promise<unknown>}
   */
  post(url, params = {}, option = {}) {
    const options = Object.assign({
      method: 'POST'
    }, option);
    const defaultType = options.headers?.type || '';
    //一般我们常用场景用的是json，所以需要在headers加Content-Type类型
    options.body = defaultType !== 'FormData' ? JSON.stringify(params) : params;

    return http.staticFetch(url, options); //类的静态方法只能通过类本身调用
  }

  /**
   * put方法
   * @param url
   * @returns {Promise<unknown>}
   */
  put(url, params = {}, option = {}) {
    const options = Object.assign({
      method: 'PUT'
    }, option);
    options.body = JSON.stringify(params);
    return http.staticFetch(url, options); //类的静态方法只能通过类本身调用
  }

  /**
   * get请求方式
   * @param url
   * @param option
   */
  get(url, params, option = {}) {
    if (params) {
      let paramsArray = [];
      //拼接参数  
      Object.keys(params).forEach(key => paramsArray.push(key + '=' + params[key]))
      if (url.search(/\?/) === -1) {
        url += '?' + paramsArray.join('&')
      } else {
        url += '&' + paramsArray.join('&')
      }
    }
    const options = Object.assign({
      method: 'GET'
    }, option);
    return http.staticFetch(url, options);
  }
}

const requestFun = new http(); //new生成实例
export const {
  post,
  get,
  put
} = requestFun;
export default requestFun;