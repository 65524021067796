import React, { useState, useEffect } from 'react';
import { Layout, Menu, Input, Row, Col } from 'antd';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import TheHeader from './TheHeader';
import Logo from '../assets/images/logo.png'
import { HomeOutlined, EditOutlined, DeleteOutlined, SearchOutlined, PlusSquareFilled, UserOutlined } from '@ant-design/icons';
import './LayoutComponent.css'
import { getUserInfo, uuid } from '../utils';
import { createDoc } from '../services/http';
const { Content, Header, Sider } = Layout;


const LayoutComponent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchText, setSearchText] = useState('')
  let [currentRouter, setCurrentRouter] = useState(['/']);
  let routers = [
    { path: '/', name: '首页', icon: <HomeOutlined /> },
    { path: '/mydoc', name: '我的文档', icon: <EditOutlined /> },
    { path: '/trash', name: '回收站', icon: <DeleteOutlined /> },
    { path: '/edit', name: '编辑页', icon: <EditOutlined /> },
  ]

  const goToEditor = () => {
    const newId = uuid()
    const res = createDoc({ doc_id: newId, uid: getUserInfo().uid, doc_name: '未命名文档' })

    res.then(res => {
      if (res.code === 0) {
        navigate(`/edit/${newId}`)
      }
    })
  }

  const handleClick = (e) => {
    setCurrentRouter(currentRouter => currentRouter = [e.key]);
  }

  const handleSearch = () => {
    navigate(`/search/?keyword=${searchText}`)
  }

  const onKeyDown = (event) => {
    if (event.keyCode == 13) {
      handleSearch()
    }
  }

  useEffect(() => {
    const urlParams = new URL(window.location.href);
    const pathname = urlParams.pathname;
    setCurrentRouter(currentRouter => currentRouter = [pathname]);
    document.oncontextmenu = function (e) {/*屏蔽浏览器默认右键事件*/
      e = e || window.event;
      return false;
    };
  }, [ 
    location.pathname
  ])

  return (
    <div className='layout'>
      <Row gutter={24}>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 24 }}>
          <Layout style={{ minHeight: '100vh' }}>
            <Sider className='sidebar'>
              <div className="logo-box">
                <img src={Logo} className='logo' alt="" />
                <span className='logo-text'>CoWord</span>
              </div>
              <div className='input-search'>
                <Input onKeyDown={onKeyDown} value={searchText} onChange={(e) => {
                  setSearchText(e.target.value)
                }} placeholder="搜索" prefix={<SearchOutlined />} />
              </div>

              <Menu
                onClick={handleClick}
                selectedKeys={location.pathname}
                mode="inline"
                theme="light"
              >
                {
                  routers.map(item => {
                    if (item.path != '/edit')
                      return (
                        <Menu.Item key={item.path} icon={item.icon}>
                          <Link to={item.path}>{item.name}</Link>
                        </Menu.Item>
                      )
                  })
                }
              </Menu>
            </Sider>
            <div className='content'>
              <Header style={{ background: 'white' }}>
                <TheHeader></TheHeader>
              </Header>
              <Content style={{ flex: '1' }}>
                <div className="site-layout-content">
                  <Outlet />
                </div>
              </Content>
            </div>
          </Layout>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 0 }}>
          <div className='content mobile-content'>
            <Header style={{ background: 'white' }}>
              <TheHeader></TheHeader>
            </Header>
            <Content style={{ flex: '1' }}>
              <div className="site-layout-content">
                <Outlet />
              </div>
            </Content>
          </div>
          <div className='footer-nav'>
            <Link className={location.pathname == '/' ? 'footer-item router-active' : 'footer-item'} to='/'>
              <HomeOutlined />
              <span>首页</span>
            </Link>
            <Link className={location.pathname == '/mydoc' ? 'footer-item router-active' : 'footer-item'} to='/mydoc'>
              <EditOutlined />
              <span>文档</span>
            </Link>
            <div className="footer-item">
              <PlusSquareFilled onClick={goToEditor} className="plus" />
            </div>
            <Link className={location.pathname == '/trash' ? 'footer-item router-active' : 'footer-item'} to='/trash'>
              <DeleteOutlined />
              <span>回收站</span>
            </Link>
            <Link className={location.pathname == '/user' ? 'footer-item router-active' : 'footer-item'} to='/user'>
              <UserOutlined />
              <span>我</span>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default LayoutComponent;