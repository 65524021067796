import ReconnectingWebSocket from 'reconnecting-websocket';
import ShareDB from 'sharedb/lib/client';
import richText from "rich-text";
import EditorEvents from './EditorEvents';

ShareDB.types.register(richText.type);

class Synchronizer {
  constructor(editor) {
    this.doc = null;
    this.editor = editor;
    this.quill = editor.quill;
    this.composition = editor.composition;
  }

  connectionWebSocket(url, docName, textType) {
    this.close();

    this.socket = new ReconnectingWebSocket(url);
    this.connection = new ShareDB.Connection(this.socket);
    this.syncShareDBDocument(docName, textType);
  }

  syncSubmitOp(delta) {
    this.doc.submitOp(delta, {
      source: 'user'
    });
    this.editor.emit(EditorEvents.userTextSave);
  }

  syncShareDBDocument(docName, textType) {
    this.doc = this.connection.get(docName, textType)
    this.doc.subscribe(error => {
      if (error) {
        console.log("Error:", error);
      }
      // set initial data of the document
      this.composition.setEditorContent(this.doc.data)
      this.doc.on("op", (delta, source) => {
        if (source === 'user') {
          return;
        }
        if (!delta.ops || delta.ops.length === 0) return;

        this.composition.submitToEditor(delta);


      });

      this.quill.getModule('history').init(this.editor)
      this.quill.getModule('counter').init(this.editor)
    });

  }

  close() {
    if (this.doc) {
      this.doc.destroy();
      this.doc = null;
    }
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }
}

export default Synchronizer;