import {
  useRef,
  useEffect
} from 'react';
import {
  Quill
} from "react-quill";
import { useCreateTip } from '../../views/module/useCreateTip';
import {
  uploadImg,
} from "../../services/http";
// QuillJS Modules

import QuillCursors from 'quill-cursors';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import MagicUrl from "quill-magic-url";
import BlotFormatter from "quill-blot-formatter";
import History from "../../module/History";
import "react-quill/dist/quill.snow.css";
import Counter from '../../module/Counter';
Quill.debug('error');
const katex = require('katex');
const win = window;
win.katex = katex;


const useCreatQuill = (handlerImg) => {
  Quill.register("modules/magicUrl", MagicUrl);
  Quill.register("modules/blotFormatter", BlotFormatter);
  Quill.register("modules/history", History);
  Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
  Quill.register('modules/cursors', QuillCursors);
  Quill.register('modules/counter', Counter)

  const quillRef = useRef(null)
  const quill = useRef(null)

  const imageHandler = (imageDataUrl, type, imageData) => {
    const file = imageData.toFile()
    const formData = new FormData()
    formData.append('file', file)
    const res = uploadImg(formData)
    res.then(res => {
      if (res.code === 0) {
        let length = quill.current.getSelection().index;
        quill.current.insertEmbed(length, 'image', res.result, 'user')
        quill.current.setSelection(length + 1);
      }
    })
  }

  const options = {
    theme: 'snow',
    modules: {
      counter: {
        container: '#counter'
      },
      cursors: {
        transformOnTextChange: true,
      },
      imageDropAndPaste: {
        handler: imageHandler
      },
      magicUrl: true,
      blotFormatter: {},
      toolbar: {
        container: '#toolbar',
      },
    },
    placeholder: '请输入内容...',
  };

  useEffect(() => {
    if (!quill.current) {
      quill.current = new Quill(quillRef.current, options);
      quill.current.getModule('toolbar').addHandler('image', handlerImg);
      useCreateTip();
    }
  }, [])

  return {
    quill,
    quillRef
  }
}

export default useCreatQuill;