import EditorEvents from "./EditorEvents";
import Composition from "./Composition";
import Synchronizer from "./Synchronizer";
import config from '../utils/config';

class Editor {
  constructor(quill, roomId, userInfo, SocketCursor) {
    this.quill = quill;
    this.userInfo = userInfo
    this.SocketCursor = SocketCursor
    this.handles = {}
    this.composition = new Composition(this);
    this.synchronizer = new Synchronizer(this);
    this.synchronizer.connectionWebSocket(`ws://${config.BASEURL}:${config.DB_PORT}`, 'examples', roomId);
    this.composition.setSynchronizer(this.synchronizer);
  
    // this.on(EditorEvents.editorTextChanged, (delta) => {
    //   let del = delta.oldDelta.compose(delta.delta)
    //   // console.log(del)
    //   this.quill.setContents(del);
    //   console.log(this.quill)
    // })
  }

  getEditorContents() {
    return this.composition.getEditorContents()
  }

  on(event, handler) {
    let handlerId = Math.ceil(Math.random() * 100000);

    if (!this.handles[event]) {
      this.handles[event] = {}
    }

    this.handles[event][handlerId] = handler;

    return handlerId;
  }

  off(event, handlerId) {
    if (this.handles[event] && this.handles[event][handlerId]) {
      delete this.handles[event][handlerId];
    }
  }

  emit(event, args) {
    if (this.handles[event]) {
      Object.keys(this.handles[event]).forEach(handlerId => {
        this.handles[event][handlerId](args);
      });
    }
  }

  close() {
    this.handles = {};
    this.synchronizer.close();
  }
}

export default Editor;