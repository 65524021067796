import {useState, useRef} from 'react';

const useCreateRole = () => {
  const readRef = useRef()
  const [readOnly, setReadOnly] = useState(false);

  const handlerImg = () => {
    if (readRef.current) return;
    let oFile = document.getElementsByClassName('ant-upload')[1].querySelector('input')
    oFile.accept = "image/x-png,image/gif,image/jpeg,image/bmp"
    oFile.click()
  }
  
  return {
    readOnly,
    readRef,
    setReadOnly,
    handlerImg
  }
}

export default useCreateRole;