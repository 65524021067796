import config from './config';
import _ from 'lodash'
import {
  message
} from 'antd';
import {
  randomColor
} from './index';
var ShareDB = require('sharedb/lib/client');

class WsClient {
  constructor(userInfo, handleUpdateUsers, doc_id, setReadOnly, editor, setLoading, goToHome) {
    this.editor = editor
    this.userInfo = userInfo;
    this.doc_id = doc_id;
    this.lockReconnect = false;
    this.timer = null;
    this.setLoading = setLoading
    this.goToHome = goToHome
    this.handleUpdateUsers = handleUpdateUsers
    this.setReadOnly = setReadOnly
    this.handlers = {}

    let self = this;

    this.docSocket = new WebSocket(`ws://${config.BASEURL}:${config.DB_PORT}`);
    this.connection = new ShareDB.Connection(this.docSocket);

    // this.connection.fetchSnapshot('examples', '7c9facb7-ba06-4d0a-9b2e-883386d50825', 2000, (err, snapshot) => {
    //   console.log(err, snapshot)
    // })

    this.heartCheck = {
      timeout: 3000,
      timeoutObj: null,
      serverTimeoutObj: null,
      start: function () {
        let fnSelf = this
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = setTimeout(function () {
          self?.send({
            type: 'HEART',
            userInfo: self.userInfo.current
          })
          fnSelf.serverTimeoutObj = setTimeout(function () {
            self?.close()
          }, fnSelf.timeout)
        }, this.timeout)
      },
      close: function () {
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = null;
        this.serverTimeoutObj = null;
      }
    }
    this.createWs();
  }

  createWs() {
    try {
      this.init()
    } catch (e) {
      this.socket && this.reconnect()
    }
  }

  init() {
    
    this.socket = new WebSocket(`ws://${config.BASEURL}:${config.WS_PORT}/?doc_id=${this.doc_id}`);

    this.socket.onopen = () => {
      this.setLoading(false)
      this.heartCheck.start();
      this.send({
        type: "login",
        data: {
          userInfo: this.userInfo.current
        }
      });
    };

    this.socket.onmessage = event => {
      this.heartCheck.start();
      let cursor = this.editor.current.composition.cursor
      let data = JSON.parse(event.data);
      if (data.type === 'USERS' || data.type === 'ROLE' || data.type === 'QUIT') {
        this.handleUpdateUsers(data.data);
      }
      if (data.type === 'CHANGE') {
        this.emit('CHANGE', data)
      }
      if (data.type === 'ROLE' && data.role.uid == this.userInfo.current.uid) {
        this.setReadOnly(data.role.role_status)
        this.userInfo.current.role_status = data.role.role_status
        message.info(`您的权限已被更改为${data.role.role_status == '0' ? '可写' : '仅读'}状态`)
      }

      if (data.type === 'CURSOR') {

        setTimeout(() => {
          if (cursor._cursors[data.userInfo.uid]) {
            cursor.moveCursor(data.userInfo.uid, data.range)
            return;
          }
          cursor.createCursor(data.userInfo.uid, data.userInfo.nickname, randomColor())
          cursor.moveCursor(data.userInfo.uid, data.range)
        }, 80)
      }
      if (data.type === 'QUIT') {
        if (cursor._cursors[data.uid]) {
          cursor.removeCursor(data.uid)
        }
      }
      if(data.type === 'DEL') {
        message.info('房主永久关闭了该文档！')
        setTimeout(() => {
          this.goToHome()
        }, 500)
        
      }



    };

    this.socket.onclose = () => {
      this.socket && this.reconnect()
    };

    this.socket.onerror = () => {
      this.socket && this.reconnect()
    }
  }

  reconnect() {
    this.setLoading(true)
    if (this.lockReconnect){ 
      this.setLoading(false)
      return;}
    this.timer && clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.createWs();
      this.lockReconnect = false;
    }, 4000)

  }


  on(key, callback) {
    if (!this.handlers[key]) {
      this.handlers[key] = callback;
    }
    this.handlers[key] = callback;
  }

  emit(key, data) {
    if (this.handlers[key]) {
      this.handlers[key](data);
    }
  }
  send(data) {
    this.socket && this.socket.send(JSON.stringify(data));
  }

  close() {
    this.socket && this.socket.close();
    this.socket = null;
  }

  closeAll() {
    this.timer && clearTimeout(this.timer);
    this.heartCheck.close()
    this.timer = null;
    this.socket && this.socket.close();
    this.socket = null;
  }

}

export default WsClient;