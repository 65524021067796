import jsPDF from "../../utils/jspdf.debug";
import html2canvas from "html2canvas";

const useExportPdf = () => {

  const htmlToPdf = (message, pdfName) => {
    let dom = document.querySelector('.ql-editor');
    message('正在生成PDF', '请稍后...')
    html2canvas(dom).then((canvas) => {
      var leftHeight = canvas.height;
      var position = 0
      var a4Width = 595.28
      var a4Height = 841.89
      var a4HeightRef = Math.floor(canvas.width / a4Width * a4Height);
      var pageData = canvas.toDataURL('image/jpeg', 1.0)
      var pdf = new jsPDF('x', 'pt', 'a4')
      var index = 0,
        canvas1 = document.createElement('canvas'),
        height;

      pdf.setDisplayMode('fullwidth', 'continuous', 'FullScreen')

      function createImpl(canvas) {
        if (leftHeight > 0) {
          index++;

          var checkCount = 0;
          if (leftHeight > a4HeightRef) {
            var i = position + a4HeightRef;
            for (i = position + a4HeightRef; i >= position; i--) {
              var isWrite = true
              for (var j = 0; j < canvas.width; j++) {
                var c = canvas.getContext('2d').getImageData(j, i, 1, 1).data
                if (c[0] != 0xff || c[1] != 0xff || c[2] != 0xff) {
                  isWrite = false
                  break
                }
              }
              if (isWrite) {
                checkCount++
                if (checkCount >= 10) {
                  break
                }
              } else {
                checkCount = 0
              }
            }
            height = Math.round(i - position) || Math.min(leftHeight, a4HeightRef);
            if (height <= 0) {
              height = a4HeightRef;
            }
          } else {
            height = leftHeight;
          }
          canvas1.width = canvas.width;
          canvas1.height = height;
          // console.log(index, 'height:', height, 'pos', position);
          var ctx = canvas1.getContext('2d');
          ctx.drawImage(canvas, 0, position, canvas.width, height, 0, 0, canvas.width, height);
          var pageHeight = Math.round(a4Width / canvas.width * height);
          pdf.setPageSize(null, pageHeight)
          pdf.addPage();
          pdf.addImage(canvas1.toDataURL('image/jpeg', 1.0), 'JPEG', 0, 0, a4Width, a4Width / canvas1.width * height)
          leftHeight -= height;
          position += height
          if (leftHeight > 0) {
            setTimeout(createImpl, 500, canvas);
          } else {
            pdf.save(pdfName + '.pdf')
          }
        }
      }

      //当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < a4HeightRef) {
        pdf.addImage(pageData, 'JPEG', 0, 0, a4Width, a4Width / canvas.width * leftHeight);
        pdf.save(pdfName + '.pdf')

      } else {
        try {
          pdf.deletePage(0);
          setTimeout(createImpl, 500, canvas);
        } catch (err) {
          console.log(err);
        }
      }
    })
  }
  
  return {
    htmlToPdf
  }
}

export default useExportPdf;