import {
  post,
  get,
  put
} from './index';
import {
  useNavigate
} from 'react-router-dom';


export const getToken = () => {
  let user = localStorage.getItem('userInfo');
  if (user) {
    return JSON.parse(user)['token']
  } else {
    useNavigate()('/login')
  }
}

export const login = (params) => {
  const res = post('/users/login', params);
  return res;
}

export const reg = (params) => {
  const res = post('/users/register', params);
  return res;
}

export const getUserInfo = (params) => {
  const res = get('/users', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  });
  return res;
}

export const createDoc = (params) => {
  const res = post('/doc', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  });
  return res;
}

export const getDoc = (params) => {
  const res = get('/doc', {
    doc_id: params
  }, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  });
  return res;
}

export const getDocList = (params) => {
  const res = get('/doc/list', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  });
  return res;
}

export const delDoc = (params) => {
  const res = post('/doc/del', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  });
  return res;
}

export const searchDoc = (params) => {
  const res = get('/doc/search', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  })
  return res;
}

export const restoreDoc = (params) => {
  const res = post('/doc/restore', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  });
  return res;
}

export const uploadImg = (params) => {
  const res = post('/doc/upload', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`,
      'type': 'FormData'
    }
  })

  return res;
}

export const updateUserInfo = (params) => {
  const res = post('/users/update', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  })
  return res;
}

export const saveRoomName = (params) => {
  const res = post('/doc/room', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  })

  return res;
}

export const reportUpdate = (params) => {
  const res = post('/doc/report', params, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  })

  return res;
}