import {
  useState
} from 'react';
import copy from 'copy-to-clipboard';
import {
  message
} from 'antd';

const useShowHide = () => {
  const [isUser, setIsUser] = useState(false);
  const [isExist, setIsExist] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (e) => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showUser = () => {
    setIsUser(true);
  };
  const handleUserOk = () => {
    setIsUser(false);
  };

  const handleUserCancel = () => {
    setIsUser(false);
  };

  const controlUserShowHide = (e) => {
    setShowSidebar(!showSidebar)
  }

  const copyUrl = () => {
    copy(`${window.location.origin}/#/login?redirect=${window.location.hash.split('#/edit/')[1]}`);
    message.success('复制成功, 快去分享给朋友吧~');
  }

  return {
    isModalVisible,
    isUser,
    isExist,
    showSidebar,
    copyUrl,
    setIsExist,
    showModal,
    handleOk,
    handleCancel,
    showUser,
    handleUserOk,
    handleUserCancel,
    controlUserShowHide
  }
}

export default useShowHide;